<template>
    <div>
        <b-row>
            <b-col md="3">
                <info-tagihan :rs_jm=rs_data.rs_jm @st_pembayaran=st_pembayaran></info-tagihan>
            </b-col>
            <b-col md="9">
                <!-- <b-row>
                    <b-col>
                        <h4> <feather-icon class="text-primary" icon="CheckSquareIcon" size="30" /> Daftar Histori Pembayaran</h4>
                    </b-col>
                </b-row> -->
                <b-row>
                    <b-col>
                        <b-card>
                            <daftar-pembayaraan :items=rs_data.rs @actPembayaran=actPembayaran></daftar-pembayaraan>
                        </b-card>

                    </b-col>
                </b-row>
            </b-col>
        </b-row> 
        <pengajuan></pengajuan>
        <detail-tagihan :rs_pembayaran=rs_pembayaran :act_pembayaran=act_pembayaran :mhs=mhs></detail-tagihan>
    </div>
</template>

<script>
    import Base from '@/config/Mixins_base';
    import axios from '@/config/Axios';
    import {
        BTable,
        BAvatar,
        BBadge,
        BRow,
        BCol,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
        BCard,
        BAlert,
        BLink,
    } from 'bootstrap-vue'
    import InfoTagihan from './component/infoTagihan.vue'
    import Pengajuan from './component/pengajuan.vue'
    import DaftarPembayaraan from './component/daftarPembayaraan.vue'
    import DetailTagihan from './component/detailTagihan.vue'
    import KonfirmasiPembayaran from './component/konfirmasiPembayaran.vue'

    export default {
        components: {
            BTable,
            BAvatar,
            BBadge,
            BRow,
            BCol,
            BFormGroup,
            BFormSelect,
            BPagination,
            BInputGroup,
            BFormInput,
            BInputGroupAppend,
            BButton,
            BCard,
            BAlert,
            BLink,
            InfoTagihan,
            Pengajuan,
            DaftarPembayaraan,
            DetailTagihan,
            KonfirmasiPembayaran
        },
        mixins: [Base],
        data() {
            return {
                rs_data: {},
                rs_pembayaran: {},
                act_pembayaran: "",
                st_validasi: "",
                mhs: {}
            }
        },
        computed: {},
        mounted() {
            this.load_data();
            this.profil_mahasiswa();
        },
        methods: {
            async profil_mahasiswa() {
                const self = this;
                await axios({
                        method: 'PUT',
                        url: '/api/mahasiswa/pembayaran/profil_mahasiswa',
                        data: {

                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.mhs = response.data.result;
                        //self.rs_level = response.data.result;

                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },
            st_pembayaran(val) {
                this.st_validasi = val;
                this.load_data();
            },
            actPembayaran(rs_pembayaran, act_pembayaran) {
                this.rs_pembayaran = rs_pembayaran
                this.act_pembayaran = act_pembayaran

            },
            async load_data() {
                const self = this;
                await axios({
                        method: 'PUT',
                        url: '/api/mahasiswa/histori_pembayaran/load_data',
                        data: {
                            status: self.st_validasi
                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.rs_data = response.data.result;

                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },
        },
    }
</script>
<style>
    .bg-card {
        background: #dbdbdb;
    }
</style>
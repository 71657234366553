<template>
    <div>
        <b-modal id="modal-2" title="Detail Tagihan" ok-only ok-title="Accept" size="xl" hide-footer>
            <b-row> 
                <b-col md="8">
                    <b-card>
                        <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

                            <!-- Header: Left Content -->
                            <div>
                                <div class="logo-wrapper"> 
                                    <h3 class="text-primary invoice-logo">
                                        Fakultas Psikologi
                                    </h3>
                                </div>
                                <p class="card-text mb-25">
                                    Jl. Soekarno-Hatta Tlogosari, Semarang 50196
                                </p>
                                <p class="card-text mb-25">
                                    Gendung H
                                </p> 
                            </div>

                            <!-- Header: Right Content -->
                            <div class="mt-md-0 mt-3">
                                <h4 class="invoice-title">
                                    Invoice
                                    <span class="invoice-number">{{ rs_pembayaran.no_inv }}</span>
                                </h4>
                                <div class="mt-md-0 mt-3">
                                    <div class="invoice-date-wrapper">
                                        <p class="invoice-date-title">
                                            Periode Pembayaran <br>{{ rs_pembayaran.tgl_mulai }} -
                                            {{ rs_pembayaran.tgl_selesai }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <b-row class="invoice-spacing">
                            <b-col md="6">
                                <h6 class=" ">
                                    Profil
                                </h6>
                                <small class="mb-25">NIM</small>
                                <h5 class="mb-25">{{ mhs.nim }}</h5>
                                <small>Nama</small>
                                <h5>{{ mhs.nm_lengkap }}</h5>
                                <small>Mahasiswa kelas <strong>{{ mhs.kelas }}</strong>, tahun angkatan
                                    <strong>{{ mhs.th_angkatan }}</strong></small>
                            </b-col>
                            <b-col md="6">
                                <h6 class=" ">
                                    Informasi Pembayaran :
                                </h6>
                                <small class="mt-5 mt-5">Pembayaran</small>
                                <h5 class="text-warning">{{ rs_pembayaran.nm_jenis_pembayaran }} - Kelas
                                    {{ rs_pembayaran.kelas }} </h5>
                                <small>Periode Semester </small>
                                <h5 class="text-warning">{{ rs_pembayaran.nm_semester }}</h5>
                                <div>
                                    <small class="mt-5 mt-5">No Rekening</small>
                                    <h5 class=" ">Bank {{ rs_pembayaran.bank }} -
                                        {{ rs_pembayaran.no_rekening }}</h5>
                                    <small>Atasnama : </small>
                                    <h5 class="">{{ rs_pembayaran.atas_nama }}</h5>
                                </div>
                            </b-col>
                        </b-row>
                        <hr>
                        <b-row class="mb-5">
                            <b-col md="6" class="mb-5">
                                <div class="">
                                    <h5>keterangan </h5>
                                    <p v-html="rs_pembayaran.keterangan"> </p>
                                    <h5>ketentuan Jenis pembayaran</h5>
                                    <p v-html="rs_pembayaran.ket_jenis_bayar"> </p>

                                </div>
                            </b-col>
                            <b-col md="6" class="mb-4">
                                <h6 class=" ">
                                    Detail Pembayaran :
                                </h6>
                                <small class="mt-5 mt-5">Tagihan </small>
                                <b-row>
                                    <b-col md="2">
                                        <h4><strong>Rp. </strong></h4>
                                    </b-col>
                                    <b-col md="10">
                                        <h1 class="text-warning">
                                            <strong>{{ Rp(rs_pembayaran.tertagih) }},-</strong>
                                        </h1>
                                    </b-col>
                                </b-row>
                                <div v-if="rs_pembayaran.terbayar != null">
                                    <small class="mt-5 mt-5">Terbayar </small>
                                    <b-row>
                                        <b-col md="2">
                                            <h4><strong>Rp. </strong></h4>
                                        </b-col>
                                        <b-col md="10">
                                            <h1 class="text-primary">
                                                <strong>{{ Rp(rs_pembayaran.terbayar) }},-</strong>
                                            </h1>
                                            <small class="mt-5 mt-5">Tanggal Bayar
                                                <strong>{{ rs_pembayaran.tgl_tagihan }}</strong> </small>
                                        </b-col>

                                    </b-row>
                                    <b-row>
                                        <b-col>
                                            <img :src="rs_pembayaran.file" width="100%">
                                        </b-col>
                                    </b-row>
                                </div>
                                <div v-if="rs_pembayaran.terbayar == null">
                                    <small>Catatan : </small>
                                    <small>
                                        ** Ketika melakukan pengajuan akan ditambah 2-3 digit unix di nominal
                                        pembayaran,
                                        harap transfer sesuai total tagihan akhir yang tertera di info tagihan
                                    </small>
                                </div>
                                <div v-if="rs_pembayaran.status_pembayaran == 'Ditolak'">
                                    <b-button block variant="danger">Pembayaran Ditolak</b-button>
                                </div>
                                <div v-if="rs_pembayaran.status_pembayaran == 'Valid'">
                                    <b-button block variant="primart">Pembayaran Tervalidasi</b-button>
                                </div>
                                <div v-if="rs_pembayaran.status_pembayaran == 'Revisi'" class="mt-1">
                                    <b-button block variant="warning">Konfirmasi Pembayaran belum bisa divalidasi, silahkan Konfirmasi Ulang </b-button>
                                </div>

                            </b-col>


                        </b-row>

                    </b-card>
                </b-col>


                <b-col md="4">
                    <b-alert variant=" " show>
                        <div class="alert-body">
                            <h4 class="text-primary"> <strong>
                                    <feather-icon icon="LayersIcon" size="15" class="mr-1 " /> Histori
                                    Pembayaran</strong></h4>
                            <hr>
                            <timeline :rs_pembayaran="rs_pembayaran"  ></timeline>
                        </div>
                    </b-alert>


                </b-col>
            </b-row>

            <b-row>
                <b-col md="6">


                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
    import {
        BForm,
        BCol,
        BModal,
        BButton,
        VBModal,
        BAlert,
        BFormInput,
        BRow,
        BFormGroup,
        BCard,
    } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'
    import Timeline from './timeline.vue'

    export default {
        components: {
            BButton,
            BForm,
            BCol,
            BModal,
            BAlert,
            BFormInput,
            BRow,
            BFormGroup,
            BCard,
            Timeline
        },
        directives: {
            'b-modal': VBModal,
            Ripple,
        },
        props: {
            rs_pembayaran: {},
            act_pembayaran: {},
            mhs: {},
            alur: {}, 
        },
        data() {
            return {
                editorOption: {
                    modules: {
                        toolbar: '#toolbar',
                    },
                },
                content: ''
            }
        },
        methods: {
            close() {
                this.$root.$emit('bv::hide::modal', 'modal-1')
            },
            Rp(value) {
                let val = (value / 1).toFixed(0).replace('.', ',')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            },
        }
    }
</script>
<template>
    <div>
        <b-row> 
            <b-col md="12">
                <b-card class="position-static  bg-primary match-height"  @click="st_pembayaran('valid')">
                    <b-row>
                        <b-col md="2">
                            <feather-icon class="text-white" icon="CheckSquareIcon" size="40" />
                        </b-col>
                        <b-col md="10">
                            <blockquote class="blockquote mb-0">
                                <h6 class="text-warning">Pembayaran Tervalidasi<strong
                                        class="text-white ml-1">({{ rs_jm.valid }})</strong></h6>
                            </blockquote>
                        </b-col>
                    </b-row> 
                </b-card>

                <b-card class="position-static bg-info   text-white  match-height" @click="st_pembayaran('konfirmasi')">
                    <b-row>
                        <b-col md="2">
                            <feather-icon class="text-white" icon="ClockIcon" size="35" />

                        </b-col>
                        <b-col md="9">
                            <blockquote class="blockquote mb-0">
                                <h6 class="text-white">Menunggu <br>Validasi <strong class="text-white">({{ rs_jm.konfirmasi }})</strong></h6> 
                            </blockquote>
                        </b-col>
                    </b-row> 
                </b-card>
                <b-card class="position-static bg-warning  text-white  match-height"  @click="st_pembayaran('pengajuan')">
                    <b-row>
                        <b-col md="2">
                            <feather-icon class="text-white" icon="UploadCloudIcon" size="35" />

                        </b-col>
                        <b-col md="9">
                            <blockquote class="blockquote mb-0">
                                <h6 class="text-white">Menunggu <br>Konfirmasi <strong class="text-white">({{ rs_jm.pengajuan }})</strong></h6> 
                            </blockquote>
                        </b-col>
                    </b-row> 
                </b-card>
                <b-card class="position-static bg-danger  text-white  match-height"  @click="st_pembayaran('ditolak')">
                    <b-row>
                        <b-col md="2">
                            <feather-icon class="text-white" icon="AlertTriangleIcon" size="35" />

                        </b-col>
                        <b-col md="9">
                            <blockquote class="blockquote mb-0">
                                <h6 class="text-white">Konfirmasi <br>Ditolak <strong class="text-white">({{ rs_jm.ditolak }})</strong></h6> 
                            </blockquote>
                        </b-col>
                    </b-row> 
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import {
        BTable,
        BAvatar,
        BBadge,
        BRow,
        BCol,
        BButton,
        BCard,
        BCardGroup,
        BCardText,
        BImg
    } from 'bootstrap-vue'
    export default {
        components: {
            BTable,
            BAvatar,
            BBadge,
            BRow,
            BCol,
            BButton,
            BCard,
            BCardGroup,
            BCardText,
            BImg
        },
        props :{
            rs_jm : {}
        },
        emits:['st_pembayaran'],
        methods:{
            st_pembayaran(val){
                this.$emit('st_pembayaran',val)
            }
        }

    }
</script>

<style>

</style>
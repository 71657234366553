<template>
    <div>
        <b-modal id="modal-1" title="Pengajuan Pembayaran" ok-only ok-title="Accept" size="xl" hide-footer>
            <b-row>
                <b-col md="8">
                    <b-card>
                        <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

                            <!-- Header: Left Content -->
                            <div>
                                <div class="logo-wrapper">
                                    <logo />
                                    <h3 class="text-primary invoice-logo">
                                        Fakultas Psikologi
                                    </h3>
                                </div>
                                <p class="card-text mb-25">
                                    Jl. Soekarno-Hatta Tlogosari, Semarang 50196
                                </p>
                                <p class="card-text mb-25">
                                    Gendung H
                                </p>
                                <p class="card-text mb-0">
                                    087832501116
                                </p>
                            </div>

                            <!-- Header: Right Content -->
                            <div class="mt-md-0 mt-3">
                                <h4 class="invoice-title">
                                    Invoice
                                    <span class="invoice-number">#PSI23098</span>
                                </h4>
                                <div class="invoice-date-wrapper">
                                    <p class="invoice-date-title">
                                        Periode Pembayaran <br>21 Agustus 2023 - 21 Agustus 2023
                                    </p>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <b-row class="invoice-spacing">
                            <b-col md="6">
                                <h6 class=" ">
                                    Tagihan ke:
                                </h6>
                                <small class="mb-25">NIM</small>
                                <h5 class="mb-25">F.111.18.0001</h5>
                                <small>Nama</small>
                                <h5>Wahyu Budi Santosa</h5>
                                <small>Mahasiswa kelas <strong>Pagi</strong>, tahun angkatan
                                    <strong>2020</strong></small>
                            </b-col>
                            <b-col md="6">
                                <h6 class=" ">
                                    Detail Pembayaran :
                                </h6>
                                <small class="mt-5 mt-5">No Rekening</small>
                                <h5 class="text-warning">Bank BRI 87-098-2098</h5>
                                <small>Atasnama : </small>
                                <h5 class="text-warning">Wahyu Budi Santosa</h5>
                            </b-col>
                        </b-row>
                        <hr>
                        <b-row class="mb-5">
                            <b-col md="6" class="mb-5">
                                <div class="">
                                    <small>Periode pembayaran </small><br>
                                    <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
                                        deserunt mollit anim id est laborum.</p>

                                </div>
                            </b-col>
                            <b-col md="6" class="mb-4">

                                <small class="mt-5 mt-5">Total Pembayaran </small>
                                <b-row>
                                    <b-col md="2">
                                        <h4><strong>Rp. </strong></h4>
                                    </b-col>
                                    <b-col md="10">
                                        <h1 class="text-primary">
                                            <strong style="font-size: 55px;">1.000.176,-</strong></h1>
                                    </b-col>
                                </b-row>
                                <small>terbilang : </small>
                                <small><strong> Satujuta seratus duapuluh tiga rupiah</strong></small>
                            </b-col>


                        </b-row>

                    </b-card>
                </b-col>


                <b-col md="4">
                    <small class="text-center">Total Ppembayaran </small><br>
                    <b-row>
                        <b-col md="1">
                            <h4><strong>Rp. </strong></h4>
                        </b-col>
                        <b-col md="8">
                            <h1 class="text-primary">
                                <strong style="font-size: 60px;">1.000.176,-</strong></h1>
                        </b-col>
                    </b-row>


                    <b-button variant="primary" class="mr-2" block>
                        <feather-icon icon="AlignJustifyIcon" size="15" /> Buat Tagihan pembayaran
                    </b-button>

                    <b-button variant="warning" block>
                        <feather-icon icon="XIcon" size="15" /> Batal
                    </b-button>

                    <b-alert variant="warning" show class="mt-2">
                        <div class="alert-body">
                            <h5 class="mt-2">Tahapan Pembayaran & Konfirmasi</h5>
                            <ul>
                                <li>Buat Tagihan pembayaran</li>
                                <li>Transfer sesuai dengan nominal yang tertera</li>
                                <li>Konfirmasi Pembayaran dengan cara
                                    <ul>
                                        <li>Masuk kemuenu payment</li>
                                        <li>Masuk ke tagihan yg sudah di create atau klik pembayaran akfif</li>
                                        <li>Klik konfirmasi Pembayaran</li>
                                        <li>Upload Bukti Bayar, input nominal rupiah yang sudah di transfer dan
                                            tgl pembayaran sesuai dengan tgl transfer</li>
                                    </ul>
                                </li>
                                <li>Pembayaran akan di validasi admin Keuangan Fakultas</li>
                                <li>Selesai</li>
                            </ul>
                        </div>
                    </b-alert>


                </b-col>
            </b-row>

            <b-row>
                <b-col md="6">


                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
    import {
        BForm,
        BCol,
        BModal,
        BButton,
        VBModal,
        BAlert,
        BFormInput,
        BRow,
        BFormGroup,
        BCard,
    } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'

    export default {
        components: {
            BButton,
            BForm,
            BCol,
            BModal,
            BAlert,
            BFormInput,
            BRow,
            BFormGroup,
            BCard
        },
        directives: {
            'b-modal': VBModal,
            Ripple,
        },
        data() {
            return {
                editorOption: {
                    modules: {
                        toolbar: '#toolbar',
                    },
                },
                content: ''
            }
        }
    }
</script>
<template>
    <div>
        <b-modal id="modal-3" title="Detail Tagihan" ok-only ok-title="Accept" size="xl" hide-footer>
            <b-row>
                <b-col md="8">
                    <b-card>
                        <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

                            <!-- Header: Left Content -->
                            <div>
                                <div class="logo-wrapper">
                                    <logo />
                                    <h3 class="text-primary invoice-logo">
                                        Fakultas Psikologi
                                    </h3>
                                </div>
                                <p class="card-text mb-25">
                                    Jl. Soekarno-Hatta Tlogosari, Semarang 50196
                                </p>
                                <p class="card-text mb-25">
                                    Gendung H
                                </p>
                                <p class="card-text mb-0">
                                    087832501116
                                </p>
                            </div>

                            <!-- Header: Right Content -->
                            <div class="mt-md-0 mt-3">
                                <h4 class="invoice-title">
                                    Invoice
                                    <span class="invoice-number">#PSI23098</span>
                                </h4>
                                <div class="invoice-date-wrapper">
                                    <p class="invoice-date-title">
                                        Periode Pembayaran <br>21 Agustus 2023 - 21 Agustus 2023
                                    </p>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <b-row class="invoice-spacing">
                            <b-col md="6">
                                <h6 class=" ">
                                    Tagihan ke:
                                </h6>
                                <small class="mb-25">NIM</small>
                                <h5 class="mb-25">F.111.18.0001</h5>
                                <small>Nama</small>
                                <h5>Wahyu Budi Santosa</h5>
                                <small>Mahasiswa kelas <strong>Pagi</strong>, tahun angkatan
                                    <strong>2020</strong></small>
                            </b-col>
                            <b-col md="6">
                                <h6 class=" ">
                                    Detail Pembayaran :
                                </h6>
                                <small class="mt-5 mt-5">No Rekening</small>
                                <h5 class="text-warning">Bank BRI 87-098-2098</h5>
                                <small>Atasnama : </small>
                                <h5 class="text-warning">Wahyu Budi Santosa</h5>
                            </b-col>
                        </b-row>
                        <hr>
                        <b-row class="mb-5">
                            <b-col md="6" class="mb-5">
                                <div class="">
                                    <small>Periode pembayaran </small><br>
                                    <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
                                        deserunt mollit anim id est laborum.</p>

                                </div>
                            </b-col>
                            <b-col md="6" class="mb-4">

                                <small class="mt-5 mt-5">Total Pembayaran </small>
                                <b-row>
                                    <b-col md="2">
                                        <h4><strong>Rp. </strong></h4>
                                    </b-col>
                                    <b-col md="10">
                                        <h1 class="text-primary">
                                            <strong style="font-size: 55px;">1.000.176,-</strong></h1>
                                    </b-col>
                                </b-row>
                                <small>terbilang : </small>
                                <small><strong> Satujuta seratus duapuluh tiga rupiah</strong></small>
                            </b-col>


                        </b-row>

                    </b-card>
                </b-col>


                <b-col md="4">
                    <b-alert variant="primary" show>
                        <div class="alert-body">
                            <h4 class="text-primary"> <strong>
                                    <feather-icon icon="CheckSquareIcon" size="25" class="mr-1 " /> Konfirmasi
                                    Pembayaran</strong></h4>
                            <hr>
                            <b-form-group label="Pembayaran Melalui" label-for="basicInput">
                                <b-form-input id="basicInput" placeholder="Enter Email" />
                                <small>Inputkan nama Bank atau metode paymen yang di gunakan (Ovo, GoPay, Dana,
                                    ect..)</small>
                            </b-form-group>
                            <b-form-group label="Tanggal pembayaran" label-for="basicInput">
                                <b-form-datepicker id="example-datepicker" v-model="value" class="mb-1" />
                                <small>Masukan tgl bayar sesuai dengan tgl yang tertera di struk pembayaran atau bukti
                                    transfer</small>
                            </b-form-group>
                            <b-form-group label="Jumlah Transfer" label-for="basicInput">
                                <b-form-input id="basicInput" placeholder="Enter Email" />
                                <small>Wajib di inputkan jika pembayaran tidak sesuai dengan nominal yang di set dari
                                    sistem</small>
                            </b-form-group>
                            <b-form-group label="Upload Bukti Bayar" label-for="basicInput">
                                <b-form-file placeholder="Choose a file or drop it here..."
                                    drop-placeholder="Drop file here..." multiple />
                                <small>Upload bukti bayar atau transfer, pastikan bukti bayar yang di upload bisa terbaca dengan jelas</small>
                            </b-form-group>
                            <hr>
                            <b-button variant="primary" class="mr-2" block>
                                <feather-icon icon="AlignJustifyIcon" size="15" class="mr-1" />Simpan Konfirmasi
                                Pembayaran
                            </b-button>

                            <b-button variant="warning" block>
                                <feather-icon icon="XIcon" size="15" /> Batal
                            </b-button>
                        </div>
                    </b-alert>


                </b-col>
            </b-row>

            <b-row>
                <b-col md="6">


                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
    import {
        BForm,
        BCol,
        BModal,
        BButton,
        VBModal,
        BAlert,
        BFormInput,
        BRow,
        BFormGroup,
        BCard,
        BFormDatepicker,
        BFormFile
    } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'

    export default {
        components: {
            BButton,
            BForm,
            BCol,
            BModal,
            BAlert,
            BFormInput,
            BRow,
            BFormGroup,
            BCard,
            BFormDatepicker,
            BFormFile
        },
        directives: {
            'b-modal': VBModal,
            Ripple,
        },
        data() {
            return {
                editorOption: {
                    modules: {
                        toolbar: '#toolbar',
                    },
                },
                content: ''
            }
        }
    }
</script>
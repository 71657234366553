<template>
    <b-row>
        <b-col md="2" sm="4" class="my-1">
            <b-form-group class="mb-0">
                <label class="d-inline-block text-sm-left mr-50"></label>
                <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
            </b-form-group>
        </b-col>
        <b-col md="4" sm="8" class="my-1">
        </b-col>
        <b-col md="6" class="my-1">
            <b-form-group label=" " label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput"
                class="mb-0">
                <b-input-group size="sm">
                    <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
                    <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''">
                            Clear
                        </b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-form-group>
        </b-col>

        <b-col cols="12">
            <b-table striped hover responsive :per-page="perPage" :current-page="currentPage" :items="items"
                :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection"
                :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
                <template #cell(avatar)="data">
                    <b-avatar :src="data.value" />
                </template>

                <template #cell(tagihan)="data">
                    <b-row>
                        <b-col md="4">
                            <h5> <strong class="text-primary">{{ data.item.nm_jenis_pembayaran }} <br>(Kelas
                                    {{  data.item.kelas }})</strong> <br> Semester
                                {{ data.item.nm_semester }}
                            </h5>
                            <b-row>
                                <b-col md="12">
                                    <small>Periode pembayaran: </small><br>
                                    {{ data.item.tgl_mulai }} - {{ data.item.tgl_selesai }}
                                </b-col>
                            </b-row>
                            <b-row class="mt-1" v-if="data.item.status_pembayaran =='Pengajuan'">
                                <b-col md="12">
                                    <b-button block variant="gradient-warning" size="sm">
                                        <b-row>
                                            <b-col md="1"></b-col>
                                            <b-col md="2">
                                                <feather-icon icon="ClockIcon" size="30" />
                                            </b-col>
                                            <b-col md="8">
                                                Menunggu Proses Konfirmasi
                                            </b-col>
                                        </b-row>
                                    </b-button>
                                </b-col>
                            </b-row>

                            <b-row class="mt-1" v-if="data.item.status_pembayaran =='Konfirmasi'">
                                <b-col md="12">
                                    <b-button block variant="gradient-info" size="sm">
                                        <b-row>
                                            <b-col md="1"></b-col>
                                            <b-col md="2">
                                                <feather-icon icon="ClockIcon" size="30" />
                                            </b-col>
                                            <b-col md="8">
                                                Menunggu Proses Validasi
                                            </b-col>
                                        </b-row>
                                    </b-button>
                                </b-col>
                            </b-row>

                            <b-row class="mt-1" v-if="data.item.status_pembayaran =='Ditolak'">
                                <b-col md="12">
                                    <b-button block variant="danger" size="sm">
                                        <feather-icon icon="AlertTriangleIcon" size="15" /> Ditolak
                                    </b-button>
                                </b-col>
                            </b-row>
                            <b-row class="mt-1" v-if="data.item.status_pembayaran =='Revisi'">
                                <b-col md="12">
                                    <b-button block variant="warning" size="sm">
                                        <feather-icon icon="AlertTriangleIcon" size="15" /> Revisi
                                    </b-button>
                                </b-col>
                            </b-row>

                            <b-row class="mt-1" v-if="data.item.status_pembayaran =='Valid'">
                                <b-col md="12">
                                    <b-button block variant="primary" size="sm">
                                        <feather-icon icon="CheckCircleIcon" size="15" /> Tervalidasi
                                    </b-button>
                                </b-col>
                            </b-row>


                        </b-col>
                        <b-col md="5">
                            <div variant="warning" show>
                                <div class="alert-body">
                                    <small>No Rekening</small>
                                    <h6 class="text-warning">{{data.item.bank}} {{data.item.no_rekening}} </h6>
                                    <small>Atasnama : </small><br>
                                    <small class="text-warning"><strong> {{ data.item.atas_nama }}</strong></small><br>
                                    <b-row class="" v-if="data.item.status_pembayaran == 'Pengajuan' ">
                                        <b-col md="12">
                                            <small>No Invoice : <strong>{{ data.item.no_inv }}</strong> </small><br>
                                            <small>Tagihan : </small><br>
                                            <h2 class="text-warning  "><strong><sup
                                                        class="mr-1">Rp.</sup>{{ Rp(data.item.tertagih) }},-</strong>
                                            </h2>

                                        </b-col>
                                    </b-row>

                                    <small><strong>Keterangan</strong> </small><br>
                                    <p> {{ data.item.keterangan }}</p>

                                </div>
                                <!-- <small class="text-warning"><strong>Ketentuan Jenis Pembayaran </strong></small><br> -->
                                <!-- <p v-html=" data.item.ket_jenis_bayar "> </p> -->
                            </div>
                        </b-col>

                        <b-col md="3">



                            <b-row class=""
                                v-if="data.item.status_pembayaran == 'Konfirmasi' || data.item.status_pembayaran =='Valid'">
                                <b-col md="12">
                                    <small><strong>{{ data.item.no_inv }}</strong></small><br>
                                    <small>Tertagih : </small><br>
                                    <h4 class=""><strong><sup
                                                class="mr-1">Rp.</sup>{{ Rp(data.item.tertagih) }},-</strong></h4>
                                    <small>Terbayar : </small><br>
                                    <h4 class="text-primary  "><strong><sup
                                                class="mr-1">Rp.</sup>{{ Rp(data.item.terbayar) }},-</strong>
                                        <br> <small>{{ data.item.tgl_tagihan }}</small> </h4>
                                </b-col>
                            </b-row> 
                            <div v-if="data.item.status_pembayaran == null ">
                                <small>Nominal </small>
                                <b-row>
                                    <b-col md="3"><strong><sup>Rp.</sup></strong></b-col>
                                    <b-col md="9">
                                        <h2 class="text-warning text-right item-price">
                                            <strong>{{ Rp(data.item.nominal) }}</strong></h2>
                                    </b-col>
                                </b-row>
                                
                            </div>

                            <div v-if="data.item.status_pembayaran == 'Ditolak' ">
                                <small>Nominal </small>
                                <b-row>
                                    <b-col md="3"><strong><sup>Rp.</sup></strong></b-col>
                                    <b-col md="9">
                                        <h2 class="text-warning text-right item-price">
                                            <strong>{{ Rp(data.item.nominal) }}</strong></h2>
                                    </b-col>
                                </b-row>
                                
                            </div>


                            <b-button v-if="data.item.status_pembayaran != null " block
                                @click="actPembayaran(data.item, 'Detail')" variant="gradient-primary" v-b-modal.modal-2
                                size="sm">
                                <feather-icon icon="LayersIcon" size="15" class="mr-1" /> Detail Tagihan
                            </b-button>  

                        </b-col>
                    </b-row>

                </template>
            </b-table>
        </b-col>
        <div v-if="items.length == 0 " class="ml=2">
            <b-row>
                <b-col md="6"></b-col>
                <b-col md="6">
                    <b-img fluid :src="sideImg" alt="Login V2" style="float: left; width: 100%;" />
                </b-col>
            </b-row>
            <div>

                <span class="ml-2 text-warning">Untuk saat ini belum ada data yang bisa kami tampilkan </span>
            </div>
        </div>

        <b-col cols="12" show variant="warning" v-if="items.length > 0 ">
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right" size="sm"
                class="my-0" />
        </b-col>
    </b-row>
</template>

<script>
    import {
        BTable,
        BAvatar,
        BBadge,
        BRow,
        BCol,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
        BCard,
        BAlert,
        BLink,
        BImg
    } from 'bootstrap-vue'

    export default {
        components: {
            BTable,
            BAvatar,
            BBadge,
            BRow,
            BCol,
            BFormGroup,
            BFormSelect,
            BPagination,
            BInputGroup,
            BFormInput,
            BInputGroupAppend,
            BButton,
            BCard,
            BAlert,
            BLink,
            BImg
        },
        props: {
            items: {}
        },
        emits: ['actPembayaran'],
        data() {
            return {
                imgUrl: '',
                sideImg: require('@/assets/images/logo/ddk.png'),
                perPage: 5,
                pageOptions: [3, 5, 10],
                totalRows: 1,
                currentPage: 1,
                sortBy: '',
                sortDesc: false,
                sortDirection: 'asc',
                filter: null,
                filterOn: [],
                infoModal: {
                    id: 'info-modal',
                    title: '',
                    content: '',
                },
                fields: [{
                    key: 'tagihan',
                    label: '',
                    thStyle: {
                        display: 'none'
                    }
                }],

            }
        },
        computed: {
            sortOptions() {
                // Create an options list from our fields
                return this.fields
                    .filter(f => f.sortable)
                    .map(f => ({
                        text: f.label,
                        value: f.key
                    }))
            },
        },
        mounted() {
            // Set the initial number of items
            this.totalRows = this.items.length
        },
        methods: {
            actPembayaran(rs_Data, act) {
                this.$emit('actPembayaran', rs_Data, act)
            },
            Rp(value) {
                let val = (value / 1).toFixed(0).replace('.', ',')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            },
            info(item, index, button) {
                this.infoModal.title = `Row index: ${index}`
                this.infoModal.content = JSON.stringify(item, null, 2)
                this.$root.$emit('bv::show::modal', this.infoModal.id, button)
            },
            resetInfoModal() {
                this.infoModal.title = ''
                this.infoModal.content = ''
            },
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length
                this.currentPage = 1
            },
        },
    }
</script>